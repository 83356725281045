<template>
  <div class="not_found">
    <h1>404</h1>
    <p>{{ $t("notFound.title") }}</p>
    <router-link tag="a" to="/">{{ $t("notFound.linkText") }}</router-link>
  </div>
</template>

<script>
export default {};
</script>

<style scoped lang="scss">
.not_found {
  min-height: 600px;
  padding: 100px 0;
  text-align: center;
  p {
    margin: 20px 0;
  }
}
</style>
